export default function tableScroll() {
    $(document).ready(function() {
        $(".content-wysiwyg table").each(function() {
            $(this).wrap('<div class="table-responsive"></div>');
            $(this).addClass('table');
            // We can not remove the style attribute because the end users want to align table cell

            // $(this).removeAttr('width style');
            // $(this).find('td').removeAttr('style');
        });

        $('.content-wysiwyg img').each(function() {
            $(this).addClass('img-fluid');
        });
    });
}
