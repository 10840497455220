'use strict';
const $ = require('jquery');
window.$ = $;
window.jQuery = $;
require('bootstrap');
// require('swiper');

import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';
import Masonry from 'masonry-layout/masonry.js';
import adminMenu from '@elasticms/admin-menu';
import '../css/app.scss';
import ajaxSearch from "./ajax-search";
import ajaxTables from "./ajax-tables";
import datepicker from "./datepicker";
import form from "./form";
import tableScroll from "./table_scroll";
import Cookies from 'js-cookie';
import cookiesBanner from "./cookiesBanner";
// import chatbot from "./chatbot";

const $body = $('body');
const $header = $('#header');
let lastWindowWidth = $(window).outerWidth();
let scrollDirection = 'down';
let lastScrollTop = 0;
const $onlineServices = $('#online-services-list');

ajaxSearch();
adminMenu();
ajaxTables();
tableScroll();
// chatbot();

function headerOnScrollHandler() {
    const newScrollTop = $(window).scrollTop();
    scrollDirection = newScrollTop > lastScrollTop ? 'down' : 'up';

    const headerTiny = $body.hasClass('header-tiny');
    if (newScrollTop > 150 && scrollDirection == 'down') {
        if (!headerTiny) {
            $body.addClass('header-tiny');

            let totalHeight = $header.find('.header-top').outerHeight();
            if ($body.hasClass('has-alert')) {
                let alertHeight = $header.find('.alert').outerHeight();
                totalHeight += alertHeight;
            }

            $('#header').css('top', -totalHeight - 1);
        }
    } else if (headerTiny && scrollDirection == 'up') {
        $body.removeClass('header-tiny');
        $('#header').css('top', 0);
    }

    lastScrollTop = newScrollTop;
}

function headerStickyResize(windowWidth) {
    if (lastWindowWidth >= 1200 && windowWidth < 1200) { // switch from xl to lg
        $body.removeClass('header-tiny');
        $('#header').css('top', 0);
        $(window).off('scroll');
    } else if (lastWindowWidth < 1200 && windowWidth >= 1200) { // switch from lg to xl
        $(window).on('scroll', headerOnScrollHandler);
        $(window).trigger('scroll');
    }
}

function sameHeight(selector) {
    let $items = $(selector);
    let maxHeight = 0;

    $items.css('height', 'auto');

    $items.each(function() {
        let itemHeight = $(this).outerHeight();

        if (itemHeight > maxHeight) maxHeight = itemHeight;
    });

    $items.css('height', maxHeight);
}

function resize() {
    let windowWidth = $(window).outerWidth();

    headerStickyResize(windowWidth);
    $('#wrapper').css('padding-top', $header.outerHeight());
    sameHeight('.footer-list-header');
    if ($onlineServices.length) {
        onlineServices(windowWidth);
    }
    breadcrumbResponsive(windowWidth, lastWindowWidth);

    lastWindowWidth = windowWidth;
}

function breadcrumbResponsive(windowWidth, lastWindowWidth, firstPaint = false) {
    if ((firstPaint && windowWidth < 768) || (lastWindowWidth >= 768 && windowWidth < 768)) {
        $('.breadcrumb .breadcrumb-home').parent().remove();
    }
}

function statsSwiper() {
    let statsSwiper = new Swiper('#stats-swiper', {
        slidesPerView: 'auto',
        freeMode: true,
    });

    statsSwiper.on('progress', function(swiper, progress) {
        if (progress <= 0) {
            swiper.$el.removeClass('mask-beginning');
            swiper.$el.addClass('mask-end'); // account for first load
            $('.swiper-custom-navigation .swiper-prev').addClass('disabled');
        } else if (progress >= 1) {
            swiper.$el.removeClass('mask-end');
            $('.swiper-custom-navigation .swiper-next').addClass('disabled');
        } else {
            $('.swiper-custom-navigation .swiper-prev').removeClass('disabled');
            $('.swiper-custom-navigation .swiper-next').removeClass('disabled');
            swiper.$el.addClass('mask-beginning mask-end');
        }
    });

    $('.swiper-custom-navigation a').on('click', function() {
        let forward = !$(this).hasClass('swiper-prev');

        let nextTranslate = statsSwiper.getTranslate();
        if (forward) {
            nextTranslate -= 300;
        } else {
            nextTranslate += 300;
        }

        statsSwiper.translateTo(nextTranslate, 500);

        return false;
    });
}

function stickyOrNotSticky($element) {
    if($element.outerHeight() + parseInt($element.css('top')) >= $(window).outerHeight() - $('.navbar').outerHeight()) {
        $element.removeClass('sticky');
    }
}

function onlineServicesToCollapse() {
    $('#online-services-list .list-group-title').removeAttr('tabindex')
        .attr({
        'data-toggle': "collapse",
        'href': "#online-services-list-collapsible",
        'role': "button",
        'aria-expanded': "false",
        'aria-controls': "online-services-list-collapsible"
    });

    $('#online-services-list-collapsible').addClass('collapse');
}

function onlineServicesFromCollapse() {
    $('#online-services-list .list-group-title').removeAttr('data-toggle href role aria-expanded aria-controls');
    $('#online-services-list .list-group-title').attr({
        'tabindex': "-1",
    });

    $('#online-services-list-collapsible').removeClass('collapse show');
}

function onlineServices(windowWidth) {
    if (lastWindowWidth >= 992 && windowWidth < 992) { // switch from lg to md
        onlineServicesToCollapse();
    } else if (lastWindowWidth < 992 && windowWidth >= 992) { // switch from md to lg
        onlineServicesFromCollapse();
    }
}

function scrollToElement($element) {
    $([document.documentElement, document.body]).animate({
        scrollTop: $element.offset().top - $('.navbar').outerHeight() - 20
    }, 200);
}

window.changeLocaleAndRestart = function(lang) {
    const iframe = document.getElementById('MB-ChatEntry').getElementsByClassName('chatbot_iframe')[0];
    iframe.contentWindow.postMessage({
        call : 'changeLocaleAndRestart',
        value: {locale: lang, originDomain: window && window.location && window.location.origin}
    }, chatServer);
};

const chatServer = $('body').data('chat-server');
const lang = $('html').attr('lang');
const locales = {
    'fr': 'fr-FR',
    'nl': 'nl-NL',
    'en': 'en-US',
    'de': 'de-DE',
};
window.masterbotChatConfiguration = {
    chatServer: chatServer,
    locale: locales[lang],
    originDomain: window && window.location && window.location.origin,
    haveMyOwnButton: true,
    fullScreen: false,
    context: {}
};

$(window).on('resize', resize);



$(document).on('click', '.MB-ChatOpener', function() {
    $('.help').removeClass('show');
});

$(document).on('click', '.search-filters .badge', function() {
    $(this).toggleClass('active');
});

$(document).on('change', '.criterion-select', function() {
    $('#criterion-submit').addClass('disabled').removeClass('pulse');
    let isLastChild = $(this).closest('.form-group').is(':last-child');
    if (!isLastChild) {
        $(this).closest('.form-group').nextAll().remove();
    }

    if ($(this).val() == 'placeholder') {
        return;
    }

    $.get(
        $('#criterion-form').data('url').replace('__CHANGEME__', $(this).val()),
        {},
        function(response) {
            if (response.type === 'info_sheet') {
                $('#criterion-submit').attr('href', response.html);
                $('#criterion-submit').removeClass('disabled').addClass('pulse');
            } else {
                $('#criterion-children').append(response.html);
            }
        }
    );
});

$(document).ready(function() {
    if (lastWindowWidth >= 1200) {
        $(window).on('scroll', headerOnScrollHandler);
    }
    if (lastWindowWidth >= 992) {
        onlineServicesFromCollapse();
    }
    if (lastWindowWidth < 992) {
        onlineServicesToCollapse();
        $('.search-filters-collapse-container').collapse('toggle');
    }
    if (lastWindowWidth < 768) {
        breadcrumbResponsive(lastWindowWidth, lastWindowWidth, true);
    }

    datepicker();
    resize(true);
    statsSwiper();
    cookiesBanner(Cookies)

    /* START lexion */
    function applyLexicon($element, key, value) {
        var full_text = $element.text();
        var full_text_replace = full_text.replace(new RegExp("\\b(" + key + ")\\b","mi"), "<abbr data-toggle=\"tooltip\" data-placement=\"top\" title=\""+ value +"\">$&</abbr>");
        $element.replaceWith(full_text_replace);
    }

    var url = $('section.content-body').data('ajax-lexicon');
    self.xhr = $.getJSON(url, {
    }).done(function(data) {
        $.each(data, function(key, value) {
            $('section.content-body').find('.content-lexicon').find('p').contents().each(function() {
                if (this.nodeType === 3) { // text
                    applyLexicon($(this), key, value);
                } else if (this.nodeName === 'SPAN') {
                    applyLexicon($(this).contents().first(), key, value);
                }
            });
        });

        $('[data-toggle="tooltip"]').tooltip();
    }).fail(function(e) {
        console.log( 'error with lexicon' );
    });
	/* END lexicon*/

    $('#header .alert').on('closed.bs.alert', function () {
        if ($header.find('.alert').length ==- 0) {
            $body.removeClass('has-alert');
        }
        $('#wrapper').css('padding-top', $header.outerHeight());
    })

    $(document).on('change', '#select-toc', function() {
        let anchor = $(this).val();

        scrollToElement($("#"+anchor));

        if (history.pushState) {
            history.pushState(null, null, "#"+anchor);
        } else {
            location.hash = "#"+anchor;
        }
    });
    $(document).on('click', '#toc a', function() {
        let anchor = $(this).attr('href');

        scrollToElement($(anchor));

        if (history.pushState) {
            history.pushState(null, null, anchor);
        } else {
            location.hash = anchor;
        }

        return false;
    });

    if ($onlineServices.length) {
        stickyOrNotSticky($onlineServices);
    }

    $('a[href$=".pdf"], a[href$=".doc"], a[href$=".docx"], a[href$=".xls"], a[href$=".pptx"], a[href$=".zip"]').attr('target', '_blank');

    // let jsTranslations = JSON.parse($('body').data('translations'));
    let jsTranslations = $('body').data('translations');
    $('a[target="_blank"]').each(function() {
        $(this).append('<span class="sr-only">'+ jsTranslations.target_blank +'</span>')
    });

    const slugify = text =>
        text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-')

    let $toc = $('#toc');
    if ($toc.length && $toc.is(':empty')) {
        let $selectToc = $('#select-toc');
        let endOfContentReached = false;

        $('.content-wysiwyg h2').each(function() {
            let text = slugify($(this).text());
            let $tocItem = $('<li class="toc-entry"><a href="#'+ text +'">'+ $(this).text() +'</a></li>');

            if (!endOfContentReached && $(this).hasClass('end-of-content-links')) {
                $tocItem.addClass('divider');
                endOfContentReached = true;
            }
            $toc.append($tocItem);

            if ($selectToc.length) {
                let $tocOption = $('<option value="'+ text +'">'+ $(this).text() +'</option>');
                $selectToc.append($tocOption);
            }

            $(this).attr('id', text);
        });
    }

    if (window.location.hash.length > 0) {
        scrollToElement($(window.location.hash));
    }

    //back2top
    let $back2top = $('.back-to-top');
    $(window).on('scroll', function() {
        const newScrollTop = $(window).scrollTop();
        if (newScrollTop > 400 && !$back2top.hasClass('active')) {
            $back2top.addClass('active');
        } else if (newScrollTop <= 400 && $back2top.hasClass('active')) {
            $back2top.removeClass('active');
        }
    });


    var gridMasonry = document.querySelector('.grid-masonry');
    if (gridMasonry != null) {
        var msnry = new Masonry( gridMasonry, {
            // options
            itemSelector: '.grid-masonry-item',
            columnWidth: '.grid-sizer',
            percentPosition: true
        });
    }
});

$(document).on('click', '.orejime', function(event) {
    window.orejime.show();
    event.preventDefault();
});

$(document).ready(function() {
    form();
});
