import datepicker from "./datepicker";
import $ from "jquery";

export default function ajaxTables() {
    const $ = require('jquery');
    window.$ = $;
    window.jQuery = $;
    require('bootstrap');
    require( 'datatables.net-bs4' );

    $(document).ready(function() {
        const filterForms = $('.table-filter-form');
        filterForms.change(function (event) { filterFormChange(event); });
        filterForms.submit(function(event) { event.preventDefault(); });

        $('.ajax-table').each(function () { makeDatatable($(this)); });
    } );

    let datatables = {};

    function filterFormChange(event) {
        event.preventDefault();

        let $filterForm = $(event.currentTarget);
        let tableId = $filterForm.data('table-id');

        $('#'+tableId).data('filters', getFormObject($filterForm));

        let datatable = datatables[tableId];
        datatable.ajax.reload(function(json) {
            $.each(json.blocks, function(i, block) { $(i).html(block); });
            datepicker();
        });
    }

    function getFormObject($form) {
        let formData = new FormData($form[0]);
        let object = {};
        formData.forEach((value, key) => {
            if(!Object.prototype.hasOwnProperty.call(object, key)){
                object[key] = value;
                return;
            }
            if(!Array.isArray(object[key])){ object[key] = [object[key]]; }
            object[key].push(value);
        });

        return object;
    }

    function makeDatatable($table) {
        let url = $table.data('url');
        let sortDir = $table.data('order-direction');
        datatables[$table.attr('id')]= $table.DataTable({
            "order": [[0, sortDir]],
            "deferRender": true,
            'processing': true,
            "ajax": {
                "url": url,
                "type": "GET",
                "cache": true,
                "data": function ( d ) {
                    let filterParams = $table.data('filters');
                    if(filterParams){
                        $.extend(d, filterParams);
                    }
                }
            },
            "initComplete": function(settings, json) {
                $.each(json.blocks, function(i, block) { $(i).html(block); });
               datepicker();
            }
        });
    }
}
